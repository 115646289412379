export default {
    mine_news_detail_title: '内容',
    mine_news_detail_type: 'カテゴリー',
    mine_news_detail_industry: '業種',
    mine_news_detail_newtitle: 'タイトル',
    mine_news_detail_cover: '表紙',
    mine_news_detail_imgs: '写真',
    mine_news_detail_intro: '概要',
    mine_news_detail_content: '内容',
    mine_news_detail_company: '会社名',
    mine_news_detail_createTime: '提出',
    mine_news_detail_edit: '編集',
    mine_news_detail_remove: '削除',
    mine_news_detail_rejectReason: '掲載不可の理由'
}
