export default {
  product_detail_title: '製品情報',
  product_detail_productName: '製品名',
  product_detail_productIntro: '製品紹介',
  product_detail_productDetail: '製品情報',
  product_detail_company: '関連企業',
  product_detail_companyIntro: '会社概要',
  product_detail_companyIntro2: '展示会/会場',
  product_detail_discussApply: '商談申込'
}
