export default {
  company_apply_title: '商談申込',
  company_apply_discussObject: '商談対象企業',
  company_apply_discussContent: '商談申込内容',
  company_apply_discussWay: '商談目的',
  company_apply_buy: '買いたい',
  company_apply_sale: '売りたい',
  company_apply_cooperateType: '商談対象',
  company_apply_cooperateProduct: '製品',
  company_apply_cooperateTech: '技術',
  company_apply_cooperateCapital: '資本',
  company_apply_cooperateDetail: '商談内容',
  company_apply_cooperateDetailPlaceholder: '半角英数・300文字以内',
  company_apply_submit: '申込を提出',
  company_apply_success: '提出成功！',
  company_apply_lookMyApply: '申込履歴を確認'
}
