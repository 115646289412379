export default {
    mine_product_title: '我的产品',
    mine_product_list: '产品列表（上限30个）',
    mine_product_add: '添加产品信息',
    mine_product_name: '产品名称',
    mine_product_intro: '简介',
    mine_product_detail: '详情',
    mine_product_imgs: '图片',
    mine_product_remove: '删除',
    mine_product_edit: '编辑',
    mine_product_noData: '暂未添加产品信息'
}
