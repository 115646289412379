import { useCallback } from 'react'
import { useMyRoute, useLocalStorage } from '~/hooks'
import { atom, useAtom } from 'jotai'
import { get } from '~/request'
import { message } from 'antd'
import { t } from 'i18next'
import { Dialog } from 'antd-mobile'

export enum EUserState {
  normal = 1,
  expired,
  invalid
}

export enum EUserIdentity {
  individual = 1,
  Vip
}

export interface IUser {
  id: number
  account: number
  //	会员状态;1:正常,2:已过期,3:已失效.
  state: EUserState
  // 用户身份; 1: 个人用户, 2: 展商.
  userIdentity: EUserIdentity
  headImg: string
  nickName: string
  mobile: number
  tradeClassifyId: number
  tradeClassifyZhName: string
  tradeClassifyJpName: string
  companyName: string
  // 账号状态;1:正常,0:禁用
  status: boolean
  // 身份;1:企业申请人,2:白名单用户
  sysIdentity: number
}
/**
 * 获取用户信息
 */

// 用户信息
export const userAtom = atom({
  id: '',
  account: '',
  //	会员状态;1:正常,2:已过期,3:已失效.
  state: 0,
  // 用户身份; 1: 个人用户, 2: 展商.
  userIdentity: 0,
  headImg: '',
  nickName: '',
  mobile: '',
  tradeClassifyId: '',
  tradeClassifyZhName: '',
  tradeClassifyJpName: '',
  companyName: '',
  // 账号状态;1:正常,0:禁用
  status: 0,
  // 身份;1:企业申请人,2:白名单用户
  sysIdentity: 0
})

/**
 * 全局状态钩子函数
 *
 * @export
 * @return {*}
 */
export function useUser() {
  const [userInfo, setUserInfo] = useAtom(userAtom)
  const { routeNames, navigate, replace, history } = useMyRoute()
  const { account, nickName, userIdentity, id, state } = userInfo

  // 是否登录
  const isLogin = !!id
  // 用户信息是否完善
  const isPerfect = !!nickName && !!account
  // 是否为vip 展商
  const isVip = userIdentity === 2
  // vip 失效
  const isFailure = isVip && state === 3

  // 退出登录
  const onLogout = useCallback(async () => {
    Dialog.confirm({
      content: t('是否确认退出登录'),
      confirmText: t('确定'),
      cancelText: t('取消'),
      onConfirm: async () => {
        window.localStorage.clear()
        replace(routeNames.Auth)
      }
    })
  }, [])
  // 是否登录鉴权
  // 普通用户
  // 发起咨询，报名活动, 拓客信息申请

  // 展商
  // 身份通过展商自动在展商列表展示；
  // 发布资讯，管理咨询信息，报名活动，申请展位，拓客信息申请

  // 游客(未授权)
  // 仅浏览页面。发起咨询、报名、申请展位、拓客信息查询时提示授权

  // 游客(未完善信息)
  // 仅浏览页面。发起咨询、报名、申请展位、拓客信息查询时提示完善信息

  // 仅判断是否需要登录
  const onAuthClick = async (fn: any) => {
    if (isLogin) {
      fn()
    } else {
      // 游客
      await message.error(t('暂无权限访问'))
      navigate(routeNames.Auth)
    }
  }
  const { loalStorageSet } = useLocalStorage()
  // 需要登录的页面
  const urlLoginedPermissions = [
    routeNames.AuthPageAccountInfo,
    routeNames.AuthPageApplyGeneral,
    routeNames.AuthPageApplyVip,
    routeNames.AuthPageIdentitySelection,
    routeNames.Mine,
    routeNames.MinePageActivity,
    routeNames.MinePageDiscussApplyRecord,
    routeNames.MinePageNotify,
    routeNames.MinePageToolRecord,
    routeNames.MinePageToolRecordDetail
  ]
  // 需要vip权限的页面
  const urlVipPermissions = [
    routeNames.MinePageCompany,
    routeNames.MinePageProduct,
    routeNames.MinePageProductAdd,
    routeNames.MinePageEquipment,
    routeNames.MinePageEquipmentAdd,
    routeNames.MinePageContact,
    routeNames.MinePageContactAdd,
    routeNames.MinePageApplySign,
    routeNames.MinePageNews,
    routeNames.MinePageNewsAdd,
    routeNames.MinePageNewsDetail,
    routeNames.MinePageCompanyEdit
  ]
  // 需要VIP有效的页面
  const urlVipEffective = [routeNames.MinePageContactAdd, routeNames.MinePageProductAdd, routeNames.MinePageEquipmentAdd, routeNames.MinePageNewsAdd]

  const routingPermissions = async (data: IUser) => {
    const noneUrl = window.location.pathname
    // 已登录
    if (data.id) {
      // 是否为会员
      const hasVip = data.userIdentity === 2
      // 身份是会员
      if (hasVip) {
        // vip 失效
        const hasFailure = hasVip && data.state === 3
        if (hasFailure && urlVipEffective.includes(noneUrl)) {
          // 会员到期
          await message.error(t('same_vipExpired'))
          history.goBack()
          return
        }
        return
      }

      // 判断需要vip 权限页面
      if (urlVipPermissions.includes(noneUrl)) {
        await message.error(t('暂无权限访问'))
        history.goBack()
        return
      }
      return
    }

    // 未登录
    if ([...urlVipPermissions, ...urlVipEffective, ...urlLoginedPermissions].includes(noneUrl)) {
      await message.error(t('暂无权限访问'))
      replace(routeNames.Auth)
      return
    }
    return
  }
  /**
   * 获取用户信息
   */
  async function getUserInfo() {
    try {
      const data = await get(`/user`)
      setUserInfo(data)
      loalStorageSet('userInfo', data)
      routingPermissions(data)
      return data
    } catch (error) {}
  }

  return {
    userInfo,
    isLogin,
    isPerfect,
    isVip,
    getUserInfo,
    onLogout,
    onAuthClick,
    isFailure
  }
}
