import common from './common'
import home from './home'
import login from './auth'
import activity from './activity'
import activityDetail from './activityDetail'
import activitySignUp from './activitySignUp'
import news from './news'
import newsDetail from './newsDetail'
import companyList from './companyList'
import companyDetail from './companyDetail'
import mine from './mine'
import companyApply from './companyApply'
import productList from './productList'
import productDetail from './productDetail'
import businessTool from './businessTool'
import businessResult from './businessResult'
import mineToolRecord from './mineToolRecord'
import mineToolRecordDetail from './mineToolRecordDetail'
import mineProduct from './mineProduct'
import mineProductAdd from './mineProductAdd'
import mineDiscussApplyDetail from './mineDiscussApplyDetail'
import mineDiscussApplyRecord from './mineDiscussApplyRecord'
import mineNews from './mineNews'
import mineNewsAdd from './mineNewsAdd'
import mineNewsDetail from './mineNewsDetail'
import mineActivity from './mineActivity'
import mineActivityDetail from './mineActivityDetail'
import same from './same'
import pc from './pc'

const translation_zh = {
  ...common,
  ...home,
  ...login,
  ...activity,
  ...activityDetail,
  ...activitySignUp,
  ...news,
  ...newsDetail,
  ...companyList,
  ...companyDetail,
  ...mine,
  ...companyApply,
  ...productList,
  ...productDetail,
  ...businessTool,
  ...businessResult,
  ...mineToolRecord,
  ...mineToolRecordDetail,
  ...mineProduct,
  ...mineProductAdd,
  ...mineDiscussApplyDetail,
  ...mineDiscussApplyRecord,
  ...mineNews,
  ...mineNewsAdd,
  ...mineNewsDetail,
  ...mineActivity,
  ...mineActivityDetail,
  ...same,
  ...pc
}

export default translation_zh
