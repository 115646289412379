export default {
    activity_detail_title: 'イベント詳細-オンライン',
    activity_detail_startDate: '開催期間',
    activity_detail_endDate: '申込締切',
    activity_detail_industry: '業界分類',
    activity_detail_address: '開催場所',
    activity_detail_totalNum: '定員',
    activity_detail_surplus: '残り',
    activity_detail_link: '会議リンク',
    activity_detail_lookAfter: '申込承認後に閲覧可能',
    activity_detail_toMeet: '参加する',
    activity_detail_content: '内容',
    activity_detail_toApply: '申込'
}
