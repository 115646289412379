import Request from './create/index'
import { message } from 'antd'
import { history } from '~/routes'
import { routeNames } from '~/routes/const'
import { apiUrl } from '~/config'

import { t } from 'i18next'

const instance = Request.create({ baseUrl: apiUrl }) as any

// 有些接口不展示报错
const ignoreToastUrl = ['/booth/check/', '/booth']
// 有些接口不需要请求判断授权
const ignoreUrl = ['wechat/user', '/booth/check/']

// 有些路由不需要授权
const ignoreRouter = ['/newsPage/detail', '/news', '/activity', '/activityPage/detail', '/companyPage/list', '/companyPage/detail']

/** 合并函数 */
// function merge(cb: any, delay = 200) {
//   let promise: Promise<any> | null
//   let timeout: any
//   let resloveHandle: any
//   let state = 0
//   return () => {
//     if (timeout) {
//       clearTimeout(timeout)
//     }

//     if (state === 0) {
//       timeout = setTimeout(async () => {
//         state = 1
//         const result = await cb()
//         resloveHandle(result)
//         promise = null
//         state = 0
//       }, delay)
//     }

//     if (!promise) {
//       promise = new Promise((resolve) => (resloveHandle = resolve))
//     }

//     return promise
//   }
// }
// 响应拦截器中打印报错的接口信息
function consoleErrMessage(res: any) {
  const href = res.href
  const { method = 'GET', params, data } = res?.config || {}

  // eslint-disable-next-line no-console
  console.log('接口请求异常', `${method}(${href})`, method === 'GET' ? params : data, res.data)
}

function consoleMessage(res: any) {
  const href = res.href
  const { method = 'GET', params, data } = res?.config || {}

  // eslint-disable-next-line no-console
  console.log('接口请求:', `${method}(${href})`, method === 'GET' ? params : data, res.data)
}

// 请求拦截器
instance.requestInterceptors.use(async (requestConfig: any) => {
  const token = window.localStorage.getItem('token')

  requestConfig.headers = requestConfig.headers || {}
  requestConfig.headers.Authorization = token
  // requestConfig.headers.Source = 'Wechat';
  const language = localStorage.getItem('language')
  requestConfig.headers.language = language === 'jp' ? 'JP' : 'ZH'

  return requestConfig
})

// 响应拦截器
instance.responseInterceptors.use((res: any) => {
  const { status, data = {}, href } = res
  let description: string | null = null

  // 添加接口请求打印功能，临时
  consoleMessage(res)

  if ([status, data.code].includes(403)) {
    window.localStorage.removeItem('token')
    history.push(routeNames.Auth)
    message.error(t('账号已被禁用'))

    throw new Error()
  }
  if ([status, data.code].includes(401)) {
    if (!ignoreUrl.some((item) => href.search(item) !== -1 || !ignoreRouter.some((it: string) => it === history.location.pathname))) {
      history.push(routeNames.Auth)
    }
  } else if (status < 200 || status >= 300 || data.code > 0) {
    description = data.description || data.msg || data.message || JSON.stringify(data) || t('网络请求失败')
  }

  if (ignoreToastUrl.some((item) => href.search(item) !== -1)) {
    description = ''
  } else {
    // 您的账号信息还未完善
    if ([status, data.code].includes(450)) {
      history.push(routeNames.AuthPageAccountInfo)
      message.error(data.description)
      throw new Error()
    }
    // 您的基础信息为完善
    if ([status, data.code].includes(451)) {
      history.push(routeNames.AuthPageApplyGeneral)
      message.error(data.description)
      throw new Error()
    }
  }

  if (description) {
    consoleErrMessage(res)
    message.error(data.description)
    throw new Error()
  }

  // if (data && data.data.code) {
  //     throw new Error();
  // }
  return res
})

instance.responseInterceptors.use(({ data }: any) => data)

export default instance
