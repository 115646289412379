export default {
    company_apply_title: '洽谈申请',
    company_apply_discussObject: '洽谈对象信息',
    company_apply_discussContent: '洽谈申请内容',
    company_apply_discussWay: '洽谈方向',
    company_apply_buy: '采购',
    company_apply_sale: '销售',
    company_apply_cooperateType: '合作类型',
    company_apply_cooperateProduct: '产品合作',
    company_apply_cooperateTech: '技术合作',
    company_apply_cooperateCapital: '资本合作',
    company_apply_cooperateDetail: '详细合作内容',
    company_apply_cooperateDetailPlaceholder: '请输入内容，300内',
    company_apply_submit: '提交申请',
    company_apply_success: '提交成功！',
    company_apply_lookMyApply: '查看我的申请'
}
