export default {
    mine_discuss_apply_record_title: '洽谈申请记录',
    mine_discuss_apply_record_myApply: '我申请的公司',
    mine_discuss_apply_record_applyMine: '申请我的公司',
    mine_discuss_apply_record_status: '审核状态',
    mine_discuss_apply_record_placeholder: '公司名称',
    mine_discuss_apply_record_name: '洽谈对象',
    mine_discuss_apply_record_applyName: '申请人',
    mine_discuss_apply_record_way: '洽谈方向',
    mine_discuss_apply_record_type: '合作类型',
    mine_discuss_apply_record_look: '查看',
    mine_discuss_apply_record_purchase: '采购',
    mine_discuss_apply_record_sale: '销售',
    mine_discuss_apply_record_product: '产品合作',
    mine_discuss_apply_record_tech: '技术合作',
    mine_discuss_apply_record_capital: '资本合作',
    mine_discuss_apply_record_platformAudit: '平台待审核',
    mine_discuss_apply_record_companyAudit: '公司待审核',
    mine_discuss_apply_record_companyAudit2: '待审核',
    mine_discuss_apply_record_meetLinks: '待上传会议',
    mine_discuss_apply_record_unDiscuss: '待沟通',
    mine_discuss_apply_record_discussed: '已沟通',
    mine_discuss_apply_record_aduitFail: '审核未通过',
    mine_discuss_apply_record_otherReject: '对方已拒绝'
}
