import { useState, useEffect, useMemo } from 'react'
import { usePersistFn } from './usePersistFn'
import { cache, swr, updates } from './swr'

/**
 *
 * 对于简单的获取数据方法的一个封装
 * @export
 * @template S 数据的类型
 * @param {string} url 接口路径
 * @param {object} params 请求参数
 */
export function useSWR<S>(
  url: any,
  params: any = {}
): {
  data: S | undefined
  isLoading: boolean
  error: any
  mutate: () => Promise<void>
  setData?: any
  noData?: boolean
} {
  const key = useMemo(() => {
    let trueUrl = ''
    if (typeof url === 'string') {
      trueUrl = url
    } else if (typeof url === 'function') {
      trueUrl = url()
    }
    if (!trueUrl) {
      return null
    }
    return trueUrl
  }, [url])

  const [dataCache, setDataCache] = useState<S>(() => (key ? cache.get(key) : undefined))
  const [err, setErr] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [noData, setNoData] = useState(false)
  const fetchFn = usePersistFn(async (isMutate = true) => {
    if (key) {
      setIsLoading(true)
      swr(key, params, isMutate)
        .then((res) => {
          setDataCache(res)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn(`%c ${key} 捕获一个错误`, error)
          setErr(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  })

  useEffect(() => {
    // 通过 updates 模拟全局 mutate
    if (key) {
      updates.set(key, setDataCache)
    }
    fetchFn(false)

    return () => {
      if (key) {
        updates.remove(key, setDataCache)
      }
    }
  }, [key])
  useEffect(() => {
    fetchFn(false)
  }, [])
  useMemo(() => {
    const newData = dataCache as any
    if (dataCache instanceof Array) {
      setNoData(newData.length === 0)
    } else if (dataCache instanceof Object) {
      setNoData(!(newData && (newData.id || newData.orderNo)))
    }
  }, [dataCache])
  return {
    data: key ? dataCache : undefined,
    isLoading,
    error: err,
    mutate: fetchFn,
    setData: setDataCache,
    noData
  }
}
