export default {
  mine_news_add_title: '新規登録',
  mine_news_add_edit_title: '編集',
  mine_news_add_type: 'カテゴリー',
  mine_news_add_type_vip: '会員動態',
  mine_news_add_industry: '業種',
  mine_news_add_industry_placeholder: '情報業界を選択してください',
  mine_news_add_newtitle: 'タイトル',
  mine_news_add_newtitle_placeholder: '半角英数・30文字以内',
  mine_news_add_cover: '表紙',
  mine_news_add_cover_placeholder: '選択してください表紙',
  mine_news_add_cover_tip: '縦横比は1:1をお勧めいたします',
  mine_news_add_cover_tip1: '建议尺寸258px*332px',
  mine_news_add_cover_tip2: '建议尺寸884px*308px',
  mine_news_add_imgs: '写真',
  mine_news_add_imgs_placeholder: '○X○をお勧めします',
  mine_news_add_into: '概要',
  mine_news_add_into_placeholder: '半角英数・50文字以内',
  mine_news_add_content: '内容',
  mine_news_add_content_placeholder: '5000文字以内、テキストのみ、文献を引用する際は出所をご記入下さい。',
  mine_news_add_submit: '提出',
  mine_news_add_save: '保存'
}
