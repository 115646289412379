export default {
  mine_activity_detail_title: '活动报名详情',
  mine_activity_detail_auditing: '審査中',
  mine_activity_detail_toJoin: '参加待ち',
  mine_activity_detail_joined: '参加済み',
  mine_activity_detail_reject: '拒否',
  mine_activity_detail_applyInfo: 'イベント情報',
  mine_activity_detail_applyerInfo: '申込者情報',
  mine_activity_detail_link: '会議リンク',
  mine_activity_detail_toMeet: '会議へ',
  mine_activity_detail_rejectReason: '不合格理由'
}
