import { get } from '~/request'

const swrConfig = {
  focusThrottleInterval: 100 // 100毫秒 内的请求当做重复请求，将直接返回缓存结果
}
/**
 * @description SWR 请求仅作用于 Get 请求，通过本方法将参数合并
 * @param {string} key swr 请求的 key，例如 /api/userInfo
 * @param {object} params 请求参数，例如 { search: 123, word: 456 }
 * @returns {string} 返回拼接后的 Get 请求 url，例如：/api/userInfo?search=123&word=456
 */
const parseUrl = (key: string, params?: any) => {
  let searchList: string[] = []
  let url = key
  // 从url解析参数
  const reg = /\?(.*)/
  const query = url.match(reg)
  if (query) {
    url = url.replace(query[0], '')
    searchList = query[1].split('&').filter(Boolean)
  }
  // 将 params 合并进去
  const keys = Object.keys(params)
  for (const key1 of keys) {
    searchList.push(`${key1}=${params[key1]}`)
  }
  if (searchList.length) {
    url += `?${searchList.join('&')}`
  }
  return url
}

/**
 * @description 接口返回数据的缓存保存地址，根据 key 来进行保存
 */
const cache = (() => {
  const cacheData: { [key: string]: any } = {}

  return {
    get: (key: string) => {
      return cacheData[key]
    },
    set: (key: string, data: any) => {
      cacheData[key] = data
    },
    remove: (key: string) => {
      cacheData[key] = null
    }
  }
})()
/**
 * @description 更新函数的保存地址，看过 swr 源码，他的 updates 比较复杂，目前就先采用这种方式来实现相同的功能
 */
const updates = (() => {
  const updateCache: { [key: string]: any[] } = {}

  return {
    get: (key: string) => {
      return updateCache[key]
    },
    set: (key: string, data: any) => {
      if (updateCache[key]) {
        updateCache[key].push(data)
      } else {
        updateCache[key] = [data]
      }
    },
    remove: (key: string, data: any) => {
      if (updateCache[key]) {
        updateCache[key] = updateCache[key].filter((it) => it !== data)
      }
    },
    update: (key: string, res: any) => {
      const list = updateCache[key]
      for (const item of list) {
        item(res)
      }
    },
    mutate: (key: string) => {
      swr(key, {}, true)
    }
  }
})()

/**
 * @description swr 请求，模仿 PC 端 hooks 请求库 useSWR 实现类似的功能，详情请参考 https://swr.vercel.app/zh-CN
 * @param {string} key swr 请求的 key，规定时间内相同 key 的请求会被认为是重复请求进行过滤，直接响应缓存值
 * @param {object} params 接口请求参数对象
 * @param {boolean} isMutate 强制重新请求
 */
const swr = (() => {
  // 接口请求时间戳
  const fetchBag: { [key: string]: number } = {}
  // 由于首次请求返回之前 cache 都是空的，所以需要控制一下
  const firstFetchBag: { [key: string]: boolean } = {}

  return (key: string, params: any = {}, isMutate = false) =>
    new Promise<any>((resolve, reject) => {
      const lastFetch = fetchBag[key] || 0
      const now = Date.now()
      const isRepeat = now - lastFetch < swrConfig.focusThrottleInterval

      if (isMutate || !isRepeat || (!cache.get(key) && !firstFetchBag[key])) {
        const url = parseUrl(key, params)
        fetchBag[key] = now
        if (!cache.get(key)) {
          firstFetchBag[key] = true
        }
        get(url)
          .then((res: any) => {
            const data = res.data || res
            cache.set(key, data)
            updates.update(key, data)
            resolve(data)
          })
          .catch((err: any) => {
            reject(err)
          })
          .finally(() => {
            firstFetchBag[key] = false
          })
      } else {
        resolve(cache.get(key))
      }
    })
})()

export { swr, cache, updates, parseUrl }
