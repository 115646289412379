export default {
  product_detail_title: '产品详情',
  product_detail_productName: '产品名称',
  product_detail_productIntro: '产品简介',
  product_detail_productDetail: '产品详情',
  product_detail_company: '所属企业',
  product_detail_companyIntro: '公司介绍',
  product_detail_companyIntro2: '公司介绍',
  product_detail_discussApply: '洽谈申请'
};
