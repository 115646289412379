export default {
  网络请求失败: '网络请求失败，请检查您的网络。',
  请选择城市: '请选择城市',
  至今: '至今',
  图片上传中: '图片上传中',
  首页: '首页',
  资讯: '资讯',
  活动: '活动',
  我的: '我的',
  请输入: '请输入',
  取消: '取消',
  取消2: '取消',
  确认: '确认',
  确定: '确定',
  下一步: '下一步',
  请选择: '请选择',
  完成: '完成',
  请输入10字内: '请输入（10字内）',
  请输入30字内: '请输入（30字内）',
  请输入50字内: '请输入（50字内）',
  请输入150字内: '请输入（150字内）',
  请输入300字内: '请输入（300字内）',
  最长6个字: '最长（6个字）',
  上一步: '上一步',
  提交审核: '提交审核',
  去首页: '去首页',
  更多: '更多',
  分钟前: '{{value}}分钟前',
  小时前: '{{value}}小时前',
  天前: '{{value}}天前',
  请联系我们服务专线: '请联系我们服务专线，分机请拨504',
  一键拨打: '一键拨打',
  搜索: '搜索',
  暂无数据: '暂无数据',
  上滑加载: '上滑加载',
  提交失败: '提交失败',
  提交成功: '提交成功',
  失败原因: '失败原因',
  知道了: '知道了',
  查看我的申请: '查看我的申请',
  提交申请: '提交申请',
  删除: '删除',
  编辑: '编辑',
  还未登录账号哦: '还未登录账号哦',
  随便看看: '随便看看',
  去登录: '去登录',
  最长8位: '最长8位',
  我是有底线的哦: '我是有底线的哦 ~ ',
  审核未通过: '审核未通过',
  审核中: '审核中',
  待审核: '待审核',
  已通过: '已通过',
  已拒绝: '已拒绝',
  查看: '查看',
  账号已被禁用: '抱歉！账号已被禁用，请联系管理员',
  没有更多了: '没有更多了',
  的: '的',
  加载中: '加载中',
  未查到相关信息换一个试试: '未查到相关信息，换一个试试',
  保存: "保存",
  请阅读完: '请阅读完',
  你的会员已到期请联系平台: '你的会员已到期，请联系平台！'
}