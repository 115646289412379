export default {
    mine_product_title: '製品管理',
    mine_product_list: '製品リスト（最大30件まで）',
    mine_product_add: '製品情報の追加',
    mine_product_name: '製品名称',
    mine_product_intro: '概要',
    mine_product_detail: '内容',
    mine_product_imgs: '画像',
    mine_product_remove: '削除',
    mine_product_edit: '編集',
    mine_product_noData: '製品情報はまだ追加されていません'
}
