export default {
  登录和注册账号: '登录&注册账号',
  登录: '登录',
  密码登录: '密码登录',
  验证码登录: '验证码登录',
  忘记密码: '忘记密码',
  忘记密码2: '忘记密码',
  用户须知: '用户须知',
  请输入账号: '请输入账号',
  请输入密码: '请输入密码',
  手机号: '手机号',
  手机号2: '手机号',
  验证码: '验证码',
  验证码2: '验证码',
  验证码3: '验证码',
  请输入手机号: '请输入手机号',
  请输入验证码: '请输入验证码',
  获取验证码: '获取验证码',
  重新获取: '重新获取',
  请输入正确的手机号: '请输入正确的手机号！',
  已发送验证码: '已发送验证码！',
  请输入正确验证码: '请输入正确验证码！',
  验证码校验失败: '验证码校验失败',
  请阅读并勾选用户须知: '请阅读并勾选用户须知',
  会员权益说明: '会员权益说明',
  我已知晓会员权益: '我已知晓会员权益，继续申请',
  我已知晓: '我已知晓',
  未注册手机号则默认注册本网站用户: '未注册手机号则默认注册本网站用户',
  登录账号: '登录账号',
  新密码: '新密码',
  新密码确认: '新密码确认',
  请输入手机尾号: '请输入手机尾号',
  请输入手机尾号2: '验证码',
  修改密码: '修改密码',
  更改绑定手机号: '更改绑定手机号',
  用户身份选择: '用户身份选择',
  申请成为会员: '申请成为会员',
  申请成为会员2: '申请成为会员',
  查看会员权益: '查看会员权益',
  用户须知2: '用户须知',
  基础信息: '基础信息',
  升级信息: '升级信息',
  行业选择: '行业选择',
  公司名: '公司名',
  姓名: '姓名',
  性别: '性别',
  部门: '部门',
  职位: '职位',
  邮箱: '邮箱',
  座机: '座机',
  请输入座机例: '请输入 （例：021-3353-8866*504）',
  国家: '国家',
  所在地区: '所在地区',
  地区选择: '地区选择',
  公司网址: '公司网址',
  联系语言: '联系语言',
  资本构成: '资本构成',
  请输入联系语言: '请输入联系语言',
  请输入资本构成: '请输入资本构成',
  确认无误下一步: '确认无误，下一步',
  确认注册: '确认注册',
  男: '男',
  女: '女',
  中文: '中文',
  日文: '日文',
  其他: '其他',
  中资: '中资',
  日资: '日资',
  中日合资: '中日合资',
  中国: '中国',
  日本: '日本',
  产业分类: '产业分类',
  产品分类: '产品分类',
  加工分类: '加工分类',
  主要产品: '主要产品',
  成立年份: '成立年份',
  一句话标语: '一句话标语',
  公司简介: '公司简介',
  公司简介2: '公司简介',
  采购需求: '采购需求',
  销售需求: '销售需求',
  公司详细地址: '公司详细地址',
  公司地址: '公司地址',
  总公司公司名: '总公司公司名',
  总公司所在都道府: '总公司所在都道府',
  公司Logo: '公司logo （建议 透明或者白底）',
  公司logo: '公司logo',
  公司宣传图: '公司宣传图',
  公司宣传图3张: '公司宣传图 （3张 建议 长方形）',
  提交成功: '提交成功',
  请耐心等待后台审核: '请耐心等待后台审核，',
  工作人员将在13个工作日内联系您: '工作人员将在1-3个工作日内联系您。',
  成为会员: '成为会员',
  个人信息保存成功: '个人信息保存成功！',
  普通用户: '普通用户',
  不能为空: '{{value}}不能为空',
  不能为空2: '不能为空',
  小数点后最多保留: '小数点后最多保留{{value}}位有效位',
  一个汉字占两个字节: '一个汉字占两个字节',
  必须是有效值: '{{value}}必须是{{regText}}',
  有效值: '有效值',
  不能多于字节: '{{value}}不能多于{{num}}字节',
  不能少于字节: '{{value}}不能少于{{num}}字节',
  nickname: '汉字、日文、数字、字母、"_" 组成',
  account: '1 到 12 位的数字、字母、"_" 组成',
  idcard: '有效身份证号',
  password: '6-15个英文、数字、下划线组合',
  qq: 'QQ 号码',
  mobile: '有效手机号码',
  integer: '整数',
  float: '数字',
  payPwd: '四位或者6位数字组成',
  bankCard: '有效银行卡号',
  url: '域名',
  email: '邮箱地址',
  notEmpty: '非空字符',
  ip: 'IP',
  fixed: '固定电话',
  请选择国家: '请选择国家',
  支持英文数字下划线组合最长15位: '支持英文、数字、下划线组合，最长15位',
  账号信息: '账号信息',
  账号昵称: '账号昵称',
  密码: '密码',
  密码确认: '密码确认',
  更换: '更换',
  更换手机号码成功: '更换手机号码成功',
  更换密码成功: '更换密码成功',
  不能有相同: '不能有相同{{value}}',
  加工分类和产品分类是其中一项必填: '加工分类和产品分类是其中一项必填',
  注册后登录账户不可更改: '注册后登录账户不可更改'
}
