export default {
    activity_signup_title: '申込',
    activity_signup_applyActivityInfo: 'イベント情報',
    activity_signup_applyerInfo: '申込者情報',
    activity_signup_company: '会社名',
    activity_signup_company_placeholder: '半角英数・30文字以内',
    activity_signup_name: '名前',
    activity_signup_placeholder: 'ご入力ください。',
    activity_signup_sex: '性别',
    activity_signup_lng: '対応言語',
    activity_signup_lng_placeholder: '半角英数・6文字以内',
    activity_signup_deptName: '部署',
    activity_signup_deptName_placeholder: '半角英数・10文字以内',
    activity_signup_position: '役職',
    activity_signup_position_placeholder: '半角英数・10文字以内',
    activity_signup_email: 'メールアドレス',
    activity_signup_phone: '携帯番号',
    activity_signup_tel: '電話番号',
    activity_signup_reason: '申込理由',
    activity_signup_reason_placeholder: '半角英数・50文字以内',
    activity_signup_confirm: '提出',
    activity_signup_applySuccess: '提出成功！',
    activity_signup_toActivity: '戻る',
    activity_signup_lookRecord: '申込履歴'
}
