import { useCallback } from 'react'
import { routeNames } from '~/routes/const'
import { useHistory } from 'react-router-dom'

export function useMyRoute() {
  const history = useHistory()
  const location = window.location
  // 公司修改的框架引入了 routeNames，为了方便使用，将路由封装一下，使用的时候可以直接提示
  const routerFn = (isReplace = false) => {
    const lastCache: Record<string, number> = {}
    return (routeKey: string, prop: Record<string, number | string> = {} as any) => {
      const path = routeKey
      const now = Date.now()
      if (now - lastCache[path] < 500) {
        return
      }
      lastCache[path] = now
      const paramList: string[] = []
      for (const [key, val] of Object.entries(prop)) {
        paramList.push(`${key}=${val}`)
      }
      const url = paramList && paramList.length > 0 ? `${routeKey}?${paramList.join('&')}` : `${routeKey}`

      if (!path) {
        // eslint-disable-next-line no-console
        console.log('navigate err, 路由传的 key 不对')
      } else if (isReplace) {
        history.replace(url)
      } else {
        history.push(url)
      }
    }
  }
  const navigate = useCallback(routerFn(false), [])

  const replace = useCallback(routerFn(true), [])

  return { navigate, replace, routeNames, history, location }
}
