export default {
  activity_detail_title: '活动详情',
  activity_detail_startDate: '活动时间',
  activity_detail_endDate: '报名截止时间',
  activity_detail_industry: '行业类型',
  activity_detail_address: '参会地址',
  activity_detail_totalNum: '名额',
  activity_detail_surplus: '剩余',
  activity_detail_link: '参会链接',
  activity_detail_lookAfter: '申请通过后可查看',
  activity_detail_toMeet: '跳转会议',
  activity_detail_content: '活动内容',
  activity_detail_toApply: '立即报名'
}
