export default {
    activity_signup_title: '活动报名',
    activity_signup_applyActivityInfo: '申请活动信息',
    activity_signup_applyerInfo: '申请人信息',
    activity_signup_company: '公司名',
    activity_signup_company_placeholder: '请输入（30字内）',
    activity_signup_name: '姓名',
    activity_signup_placeholder: '请输入',
    activity_signup_sex: '性别',
    activity_signup_lng: '联系语言',
    activity_signup_lng_placeholder: '最长6个字',
    activity_signup_deptName: '部门',
    activity_signup_deptName_placeholder: '请输入（10字内）',
    activity_signup_position: '职位',
    activity_signup_position_placeholder: '请输入（10字内）',
    activity_signup_email: '邮箱',
    activity_signup_phone: '手机号',
    activity_signup_tel: '座机',
    activity_signup_reason: '申请理由',
    activity_signup_reason_placeholder: '请输入（50字内）',
    activity_signup_confirm: '提交申请',
    activity_signup_applySuccess: '提交申请成功！',
    activity_signup_toActivity: '去活动中心',
    activity_signup_lookRecord: '查看申请记录'
}
