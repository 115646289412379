/**
 *  操作存储
 */
export function useLocalStorage() {
  // 存储loalStorage
  const loalStorageSet = (key: string, value: any) => {
    if (!key) return
    let newValue = value
    if (typeof value !== 'string') {
      newValue = JSON.stringify(value)
    }
    window.localStorage.setItem(key, newValue)
  }

  // 获取localStorage
  const loalStorageGet = (key: string, isJson = true) => {
    if (!key) return
    const stringVal = window.localStorage.getItem(key) || ''
    if (!stringVal) return
    return isJson ? JSON.parse(stringVal) : (stringVal as any)
  }

  // 删除localStorage
  const loalStorageRemove = (key: string) => {
    if (!key) return
    window.localStorage.removeItem(key)
  }

  return { loalStorageSet, loalStorageGet, loalStorageRemove }
}
