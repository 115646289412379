export default {
    business_result_title: '拓客查询结果',
    business_result_searchTo: '查询到',
    business_result_numInfo: '条匹配信息',
    business_result_companyName: '企业名称',
    business_result_way: '具体委托方向',
    business_result_detailNeed: '详细需求描述',
    business_result_potentialLabel: '潜在客户调查申请',
    business_result_apply: '申请获取信息',
    business_result_potentialTips: '请简单描述贵司主要想销售的产品，该产品的主要用途，贵司想开拓哪些行业或领域的潜在客户候补企业，以及其他的需求。',
    business_result_supplierTips: '请填写采购/委托加工产品的名称，数量，金額，产品应用领域，以及希望提供几家供应商候选名单等需求',
    business_result_corporateReputationTips: '请填写调查对象的企业名称，地址，联系电话，需要调查2年还是3年的财务信息，希望交期等需求',
    business_result_actionReportTips: '请简单描述一下调查的目的，对象企业/产品，以及想了解哪些市场、产品或企业信息等',
    business_result_MATips: '请简单描述并购目的，标的企业类型，以及对标的企业的筛选要求或条件等',
    business_result_waitTips: '非常感谢贵司下单，\n我们的工作人员将在1-3个工作日\n内联系您，请耐心等待。',
    business_result_lookReord: '查看我的提交',
    business_result_goBack: '返回筛选',
    business_result_rejectReason: '未通过理由'
}
