export default {
    mine_news_detail_title: '资讯详情',
    mine_news_detail_type: '资讯类型',
    mine_news_detail_industry: '资讯行业',
    mine_news_detail_newtitle: '资讯标题',
    mine_news_detail_cover: '资讯封面',
    mine_news_detail_imgs: '资讯图片',
    mine_news_detail_intro: '资讯简介',
    mine_news_detail_content: '资讯内容',
    mine_news_detail_company: '企业名称',
    mine_news_detail_createTime: '提交时间',
    mine_news_detail_edit: '编辑资讯',
    mine_news_detail_remove: '删除资讯',
    mine_news_detail_rejectReason: '拒绝原因'
}
