import requestInstance from './instance'

type Parameters<T> = T extends (...args: infer T) => any ? T : never

export const API = {} as any

export const api = API

export function apiFn<P extends typeof API, T extends keyof typeof API>(url: T, ...args: any) {
  return (api[url] as any).apply(api, args)
}

export const get = (url: string, params?: Record<string, any>) => {
  return requestInstance({ url, params, method: 'GET' })
}
export const post = (url: string, params?: Record<string, any>) => requestInstance.post(url, params)
export const put = (url: string, params?: Record<string, any>) => requestInstance.put(url, params)
export const del = (url: string, params?: Record<string, any>) => requestInstance.delete(url, params)
