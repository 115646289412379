import { useState, useEffect, useMemo } from 'react'
import { usePersistFn } from './usePersistFn'
import { useSWR } from './useSWR'
import { cache, parseUrl } from './swr'

/**
 * 对于简单的获取数据方法的一个封装
 * @export
 * @template S 数据的类型
 * @param {string} url 接口路径
 * @param {object} params 额外的参数
 */
export function useSWRList<S>(url: any, params: any = { pageSize: 5 }, itemKey = 'id') {
  const [pageInfo, setPageInfo] = useState<any>({
    pageSize: params.pageSize,
    pageIndex: 1
  })
  // 将 url，params，pageInfo 总和生成一个 key，用于当成 SWR 的 key
  const key = useMemo(() => {
    let trueUrl = ''
    if (typeof url === 'string') {
      trueUrl = url
    } else if (typeof url === 'function') {
      trueUrl = url()
    }
    if (!trueUrl) {
      return null
    }
    return parseUrl(trueUrl, { ...params, ...pageInfo })
  }, [url, pageInfo, params])
  const [noData, setNoData] = useState(false)
  // 请求开始
  const { data, isLoading, mutate } = useSWR<any>(key)
  const [dataCache, setDataCache] = useState<S[]>(() => {
    if (key) {
      return cache.get(key) ? cache.get(key).list : []
    }
    return []
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [noMore, setNoMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  const [total, setTotal] = useState(0)
  const state = useMemo(() => {
    if (isLoading) {
      return pageInfo.pageIndex === 1 ? 1 : 2
    }
    return 0
  }, [isLoading, pageInfo])
  // 修改页码参数，触发 swr 更新
  const fetchList = usePersistFn((current?: number) => {
    const page = typeof current === 'number' ? current : currentPage + 1
    if (page !== pageInfo.pageIndex) {
      setPageInfo((obj: any) => ({
        ...obj,
        pageIndex: page
      }))
    } else {
      mutate()
    }
  })
  const onUpdate = usePersistFn((upKey: any, upData: any) => {
    setDataCache((lastData) => {
      if (!lastData) {
        return []
      }
      const idx = lastData.findIndex((it: any) => `${it[itemKey]}` === `${upKey}`)
      const mockList = [...lastData]
      mockList.splice(idx, 1, upData)
      return mockList
    })
  })
  const onRemove = usePersistFn((rmKey: any) => {
    setDataCache((lastData) => {
      if (!lastData) {
        return []
      }
      return lastData.filter((it: any) => it[itemKey] !== rmKey)
    })
  })
  const onRefresh = usePersistFn(() => fetchList(1))

  useEffect(() => {
    if (data && data.list) {
      setDataCache((list) => {
        if (data.pageNum === 1) {
          return data.list
        }
        const ids = list.map((item: any) => item[itemKey])
        const newList = data.list.filter((item: any) => !ids.includes(item[itemKey]))
        return [...list, ...newList]
      })
      setNoMore(!data.hasNextPage)
      setHasMore(data.hasNextPage)
      setCurrentPage(Number(data.pageNum))
      setTotal(Number(data.total))
    }
  }, [data])

  useMemo(() => setNoData(data && data.isLastPage && data.total === 0 && dataCache.length === 0), [data, dataCache])

  const isLoadMore = useMemo(() => !noMore && !isLoading && state === 0, [isLoading, state, noMore])
  return {
    noData,
    list: dataCache,
    isLoading,
    pageInfo: {
      ...pageInfo,
      current: currentPage,
      total
    },
    setPageInfo,
    noMore,
    state,
    pullToRefreshProps: {
      state,
      noMore,
      hideMore: dataCache.length === 0,
      onRefresh,
      onScrollToLower: fetchList
    },
    setList: setDataCache,
    fetchList,
    onUpdate,
    onRemove,
    onRefresh,
    isLoadMore,
    hasMore
  }
}
