export default {
    mine_activity_detail_title: '活动报名详情',
    mine_activity_detail_auditing: '待审核',
    mine_activity_detail_toJoin: '待参加',
    mine_activity_detail_joined: '已参加',
    mine_activity_detail_reject: '已拒绝',
    mine_activity_detail_applyInfo: '申请活动信息',
    mine_activity_detail_applyerInfo: '申请人信息',
    mine_activity_detail_link: '参会链接',
    mine_activity_detail_toMeet: '跳转会议',
    mine_activity_detail_rejectReason: '未通过原因'
}
