/**
 * @description 解析 location.search, 返回参数对象
 */
export function useParams() {
  const query = (location.search || '').slice(1)
  const querys = decodeURI(query)
  const paramslist = querys.split('&')
  const data: { [key: string]: string } = {}
  paramslist.forEach((item) => {
    const list = item.split('=')
    data[list[0]] = list[1]
  })

  return data
}
