export default {
  登录和注册账号: 'ログイン＆登録',
  登录: 'ログイン',
  密码登录: 'パスワード',
  验证码登录: '認証番号',
  忘记密码: 'パスワード再設定',
  忘记密码2: 'パスワードを忘れた',
  用户须知: '利用規約',
  请输入账号: 'アカウント名を入力',
  请输入密码: 'パスワードを入力',
  手机号: '携帯番号',
  手机号2: '携帯番号をリンクさせる',
  验证码: '認証番号でログイン',
  验证码2: '認証番号を入力',
  验证码3: '認証番号',
  请输入手机号: '携帯番号を入力してください。',
  请输入验证码: '認証番号でログインを入力してください。',
  获取验证码: '認証番号を受信',
  重新获取: '再取得する。',
  请输入正确的手机号: '正しい携帯番号を入力してください!',
  已发送验证码: '認証コードを送りました。',
  请输入正确验证码: '正しい認証コードを入力してください。',
  验证码校验失败: '認証コードの校正に失敗しました。',
  请阅读并勾选用户须知: 'ユーザー注意事項を読んでチェックしてください。',
  会员权益说明: '会員サービス',
  我已知晓会员权益: '会員サービスを確認しました、申込手続きへ進みます？',
  我已知晓: '私は知っています。',
  未注册手机号则默认注册本网站用户: '登録されていない携帯番号を使う場合は、本サイトを自動登録します',
  登录账号: 'アカウント名',
  新密码: '新しいパスワード',
  新密码确认: '新しいパスワード再確認',
  请输入手机尾号: '番号下4桁が',
  请输入手机尾号2: 'の携帯に着信した認証番号を入力',
  修改密码: 'パスワードを変更',
  更改绑定手机号: '携帯電話番号を変更',
  用户身份选择: 'ユーザータイプを選択',
  申请成为会员: '入会申込',
  查看会员权益: '会員サービスを確認',
  用户须知2: '利用規約を確認',
  基础信息: '基礎情報',
  升级信息: '公開情報',
  行业选择: '業界選択',
  公司名: '会社名',
  姓名: '名前',
  性别: '性別',
  部门: '部署',
  职位: '役職',
  邮箱: 'メールアドレス',
  座机: '電話番号',
  请输入座机例: '入力してください （例：021-3353-8866*504）',
  国家: '国家',
  所在地区: '所在地',
  地区选择: '地域の選択',
  公司网址: 'ホームページ',
  联系语言: '対応言語',
  资本构成: '資本構成',
  请输入联系语言: '対応言語を入力してください。',
  请输入资本构成: '資本構成を入力してください。',
  确认无误下一步: '入力した内容を確認',
  确认注册: '確認',
  男: '男',
  女: '女',
  中文: '中文',
  日文: '日本語',
  其他: 'その他',
  中资: '中国資本',
  日资: '日本資本',
  中日合资: '中日合資',
  中国: '中国',
  日本: '日本',
  产业分类: '産業分類',
  产品分类: '製品分類',
  加工分类: '加工分類',
  主要产品: '主要製品',
  成立年份: '設立年',
  一句话标语: 'キャチコピー',
  公司简介: '会社紹介',
  公司简介2: '会社概要',
  采购需求: '調達案件',
  销售需求: '販売案件',
  公司详细地址: '住所',
  公司地址: '会社の住所',
  总公司公司名: '本社名',
  总公司所在都道府: '本社住所',
  公司Logo: '会社ロゴ（透明または白地をお勧めします）',
  公司logo: '会社ロゴ',
  公司宣传图3张: '会社写真（3枚まで、○X○をお勧めします）',
  公司宣传图: '会社写真',
  提交成功: '提出成功',
  请耐心等待后台审核: '審査結果',
  工作人员将在13个工作日内联系您: 'は1-3営業日内にご連絡いたします',
  成为会员: '会員になる',
  个人信息保存成功: '登録情報が保存されました!',
  普通用户: '一般ユーザー',
  不能为空: '{{value}}は空になってはいけない',
  不能为空2: 'は空になってはいけない',
  小数点后最多保留: '小数点後、最大{{value}}ビットの有効ビットを残します。',
  一个汉字占两个字节: '一つの漢字が二バイトを占める。',
  必须是有效值: '{{value}}は必ず{{regText}}でなければなりません',
  有效值: '有効値',
  不能多于字节: '{{value}}は{{num}}バイト以上ではいけない。',
  不能少于字节: '{{Value}}は{{num}}バイト以下であってはいけません。',
  nickname: '漢字、日本語、数字、アルファベット、「_」で構成されています。',
  account: '1から12桁の数字、アルファベット、「_」で構成されています。',
  idcard: '有効な身分証明書番号',
  password: '6-15個の英語、数字、下線の組み合わせ',
  qq: 'QQ 号',
  mobile: '有効な携帯電話番号',
  integer: '整数',
  float: '数字',
  payPwd: '四桁または6桁の数字で構成されています。',
  bankCard: '有効な銀行カード番号',
  url: 'ドメイン',
  email: 'メールアドレス',
  notEmpty: '非空の文字',
  ip: 'IP',
  fixed: '固定電話',
  请选择国家: '国を選んでください。',
  支持英文数字下划线组合最长15位: '半角英数、15文字以内',
  账号信息: 'アカウント名',
  账号昵称: 'ユーザー名',
  密码: 'パスワード',
  密码确认: 'パスワード確認',
  更换: '変更',
  更换手机号码成功: '携帯電話番号の変更が成功しました',
  更换密码成功: 'パスワードの変更が成功しました',
  不能有相同: '同じ{{value}}があってはいけない。',
  加工分类和产品分类是其中一项必填: '加工分類と製品分類は必須です',
  注册后登录账户不可更改: '登録IDは一旦設定してから変更はできません'
}
