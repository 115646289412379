export default {
    company_detail_title: '企業情報',
    company_detail_companyName: '会社名',
    company_detail_slogan: 'キャチコピー',
    company_detail_companyIntro: '会社概要',
    company_detail_estateValue: '産業分類',
    company_detail_productValue: '製品分類',
    company_detail_processValue: '加工分類',
    company_detail_majorProduct: '主要製品',
    company_detail_years: '設立年',
    company_detail_fairProduct: '調達案件',
    company_detail_saleProduct: '販売案件',
    company_detail_addressDetail: '住所',
    company_detail_headCompanyName: '本社名',
    company_detail_headLocated: '本社住所',
    company_detail_intro: '概要',
    company_detail_detail: '詳細',
    company_detail_deviceLabel: '加工設備情報',
    company_detail_deviceName: 'デバイス名',
    company_detail_manufacturer: 'メーカー',
    company_detail_model: '型番',
    company_detail_num: '保有台数',
    company_detail_deviceYears: '設備年代',
    company_detail_introductionPeriod: '導入時期',
    company_detail_deviceUrl: '参考URL',
    company_detail_specIntro: '仕様・特徴の説明',
    company_detail_discussApply: '商談申込',
    company_detail_detailInfo: '詳細',
    company_detail_productInfo: '製品情報',
    company_detail_deviceInfo: '設備情報'
}
