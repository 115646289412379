import React, { useState, useEffect, useMemo } from 'react'
import { usePersistFn } from './usePersistFn'
import { cache, updates } from './swr'
import { get } from '~/request'

/**
 * 对于简单的获取数据方法的一个封装
 * @export
 * @template S 数据的类型
 * @param {string} url 接口路径
 * @param {object} params 请求参数
 */
export function useGet<S>(
  url: any,
  params: any = {}
): {
  data: S | undefined
  isLoading: boolean
  error: any
  mutate: () => Promise<void>
  noData?: boolean
  isfirstFetch?: boolean
  setData: React.Dispatch<React.SetStateAction<S>>
} {
  const key = useMemo(() => {
    let trueUrl = ''
    if (typeof url === 'string') {
      trueUrl = url
    } else if (typeof url === 'function') {
      trueUrl = url()
    }
    if (!trueUrl) {
      return null
    }
    return trueUrl
  }, [url])

  const [dataCache, setDataCache] = useState<S>(() => (key ? cache.get(key) : undefined))
  const [err, setErr] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isfirstFetch, setisFirstFetch] = useState(true)

  const fetchFn = usePersistFn(async () => {
    if (key) {
      setIsLoading(true)
      get(key, params)
        .then((res: any) => {
          setDataCache(res)
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.warn(`%c ${key} 捕获一个错误`, error)
          setErr(error)
        })
        .finally(() => {
          setIsLoading(false)
          setisFirstFetch(false)
        })
    }
  })

  useEffect(() => {
    // 通过 updates 模拟全局 mutate
    if (key) {
      updates.set(key, setDataCache)
    }
    fetchFn()

    return () => {
      if (key) {
        updates.remove(key, setDataCache)
      }
    }
  }, [key])
  useEffect(() => {
    fetchFn()
  }, [])

  const noData = useMemo(() => {
    if (!isfirstFetch) {
      if (dataCache !== undefined) {
        if (dataCache instanceof Array) {
          return dataCache.length === 0
        } else if (dataCache instanceof Object) {
          return Object.keys(dataCache).length === 0
        }
      }
      return false
    }
    return false
  }, [isfirstFetch, dataCache])

  return {
    data: key ? dataCache : undefined,
    isLoading,
    error: err,
    mutate: fetchFn,
    setData: setDataCache,
    noData,
    isfirstFetch
  }
}
