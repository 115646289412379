import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translation_jp from './jp'
import translation_zh from './zh'

const resources = {
  jp: {
    translation: translation_jp
  },
  zh: {
    translation: translation_zh
  }
}

const detectLanguage = (defaultLang = 'en-US') => navigator.language || (Array.isArray(navigator.languages) && navigator.languages[0]) || defaultLang
const language = localStorage.getItem('language')
const langLocal = ['zh-CN', 'zh'].includes(detectLanguage()) ? 'zh-CN' : 'jp'
i18n
  .use(LanguageDetector) // 嗅探当前浏览器语言
  .use(initReactI18next) // init i18next
  .init({
    // 引入资源文件
    resources,
    lng: language ? language : langLocal,
    // 选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: 'zh-CN',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
