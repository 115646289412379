export default {
    company_detail_title: '企业详情',
    company_detail_companyName: '公司名称',
    company_detail_slogan: '一句话标语',
    company_detail_companyIntro: '公司简介',
    company_detail_estateValue: '产业分类',
    company_detail_productValue: '产品分类',
    company_detail_processValue: '加工分类',
    company_detail_majorProduct: '主要产品',
    company_detail_years: '成立年份',
    company_detail_fairProduct: '采购需求',
    company_detail_saleProduct: '销售需求',
    company_detail_addressDetail: '公司详细地址',
    company_detail_headCompanyName: '总公司名',
    company_detail_headLocated: '总公司所在都道府',
    company_detail_intro: '简介',
    company_detail_detail: '详情',
    company_detail_deviceLabel: '加工设备信息',
    company_detail_deviceName: '设备名称',
    company_detail_manufacturer: '制造厂商',
    company_detail_model: '型号',
    company_detail_num: '拥有数量',
    company_detail_deviceYears: '设备年代',
    company_detail_introductionPeriod: '引进时期',
    company_detail_deviceUrl: '参考URL',
    company_detail_specIntro: '规格・特征说明',
    company_detail_discussApply: '洽谈申请',
    company_detail_detailInfo: '详细信息',
    company_detail_productInfo: '产品信息',
    company_detail_deviceInfo: '设备信息'
}
